import * as React from "react";
import { graphql } from "gatsby";
import Container from "../../components/container";
import Layout from "../../components/layout";
import pdf_icon_blue from "../../assets/pdf_icon_blue.svg";
import ReactMarkdown from "react-markdown";
import {
  mainContent,
  introSection,
  // description,
  row,
  // col6,
  col12,
  // marginTopSubstitute,
} from "../subpage.module.scss";

const ClinicalDataRepositoryPage = ({ data }) => {
  return (
    <Layout pageTitle="Release Notes" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
          <Container mdxPageWidth={true}>
            <div className={row}>
              <div className={col12}>
                {/* <p className={description}></p> */}
                <h1>i-engine Neue­rungen</h1>
                <p className="text-medium">
                  Download der Release Notes als PDF.
                </p>
              </div>
            </div>

            <div className={row}>
              <div className={col12}>
                {!!data.releaseNotes?.nodes &&
                  data.releaseNotes.nodes.map((node) => (
                    <div key={node.id} style={{ marginBottom: 32 }}>
                      <a
                        href={node.childMdx.frontmatter.pdf.publicURL}
                        className="text-purple"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <h2
                          className="text-large"
                          style={{
                            marginBottom: "-16px",
                            display: "inline-block",
                          }}
                        >
                          <img
                            style={{
                              marginRight: 16,
                              width: 30,
                              height: "auto",
                            }}
                            src={pdf_icon_blue}
                            alt=""
                          />
                          Release Notes v{node.name}&nbsp;&gt;
                        </h2>
                      </a>
                      <div>
                        <ReactMarkdown>{node.childMdx.body}</ReactMarkdown>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export const query = graphql`
  {
    releaseNotes: allFile(
      filter: {
        sourceInstanceName: { eq: "release-notes" }
        extension: { eq: "mdx" }
      }
      sort: { order: DESC, fields: name }
    ) {
      nodes {
        childMdx {
          frontmatter {
            pdf {
              publicURL
            }
          }
          body
        }
        id
        name
      }
    }
  }
`;

export default ClinicalDataRepositoryPage;
